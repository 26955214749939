import request from '@/service/request'

// 获取消息通知列表
export const getNoticeList = (params) => {
  return request.post('/app/sysMsg/page', params)
}

// 添加消息通知
export const addNotice = (params) => {
  return request.post('/app/sysMsg/save', params)
}

// 添加消息通知
export const sendNoticeApi = (params) => {
  return request.post('/app/sysMsg/sendMsg', params)
}

// 删除消息通知
export const deleteNoticeApi = (id) => {
  return request.post(`/app/sysMsg/deleteMsg/${id}`)
}

// 根据id获取消息通知信息
export const getNoticeById = (params) => {
  return request.post('/app/sysMsg/getById/' + params)
}
