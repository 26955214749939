<template>
  <table-view
    :total="total"
    :query-info.sync="queryInfo"
    v-loading="loading"
    @reload-table="renderTable"
  >
    <template #header>
      <view-search-form
        :query-info.sync="queryInfo"
        :tool-list="['keyword']"
        :show-clear-btn="false"
        style="display:inline-block;"
        @on-search="renderTable(1)"
      />
      <div class="header-button fr">
        <el-button v-permission="['app:notice:edit']" size="small"
                   @click="showSubDialog('visibleWxAppDialog','wxAppDialogRef')">
          管理小程序原始ID
        </el-button>
        <el-button v-permission="['app:notice:edit']" type="primary" size="small" @click="showDialog()">添加通知
        </el-button>
      </div>
    </template>
    <!-- body -->
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column type="index" label="ID" width="50" />
      <el-table-column label="接收人群" width="80">
        <template v-slot="{ row }">
          <el-button type="primary" size="mini" @click="showDialog(row.id,row)">
            {{ row.userType === 0 ? '全部' : (row.userType === 3 ? '教职工' : '学生') }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="接收范围" width="80">
        <template v-slot="{ row }">
          {{ $http.getParamName(row.msgArea, msgAreaList) }}
        </template>
      </el-table-column>
      <el-table-column label="通知标题" prop="msgTitle" min-width="200" />
      <el-table-column prop="msgProfile" label="通知内容" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column label="操作" width="100" fixed="right" align="center">
        <template v-slot="{ row }">
          <!--  只能编辑管辖学院的通知 -->
          <template
            v-if="!$store.state.login.userInfo.collegeId||$store.state.login.userInfo.collegeId===row.collegeId">
            <el-button v-permission="['app:notice:del']"
                       size="mini" type="danger"
                       @click="del(deleteNoticeApi,row.id,`消息通知-${row.msgTitle}`)">删除
            </el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <addNotice-dialog ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog" :msg-area-list="msgAreaList"
                      @on-close="renderTable" />
    <!-- 小程序原始ID管理 -->
    <wx-app-id-dialog ref="wxAppDialogRef" :visible.sync="visibleWxAppDialog" v-if="visibleWxAppDialog" />
  </table-view>
</template>
<script>
import { getNoticeList, deleteNoticeApi } from '@/api/notice'
import addNoticeDialog from './component/addNotice.vue'
import tableView from '@/vue/mixins/table-view'
import WxAppIdDialog from '@/views/pages/appManage/notice/component/wxAppIdDialog.vue'

export default {
  name: 'noticeList',
  components: {
    WxAppIdDialog,
    addNoticeDialog
  },
  mixins: [tableView],
  data () {
    return {
      visibleWxAppDialog: false,
      msgAreaList: [] // 发送类型
    }
  },
  mounted () {
    this.$http.getParamListByKey('sendType', this, 'msgAreaList')
  },
  methods: {
    deleteNoticeApi, // 删除api
    // 获取消息通知列表
    renderTable (pageNum) {
      this.getTableData(getNoticeList, pageNum)
    }
  }
}
</script>
