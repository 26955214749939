<template>
  <el-dialog
    class="notice-dialog"
    :title="`${id?'查看':'推送'}消息通知`"
    :visible.sync="show"
    :close-on-click-modal="false"
    :width="id?'720px':'540px'"
    v-loading="loadData.loading"
    :before-close="cancel"
  >
    <el-descriptions v-if="id" :column="2" border>
      <el-descriptions-item label-class-name="descriptions-item__label" :span="2" label="通知标题">
        {{ title.msgTitle }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" :span="2" label="通知内容">
        {{ title.msgProfile }}
      </el-descriptions-item>
      <el-descriptions-item v-if="title.msgType === 5" label-class-name="descriptions-item__label" :span="2"
                            label="文章链接">
        {{ title.linkUrl }}
      </el-descriptions-item>
      <el-descriptions-item v-if="title.msgType === 6" label-class-name="descriptions-item__label"
                            label="小程序原始ID">
        {{ title.wxAppId }}
      </el-descriptions-item>
      <el-descriptions-item v-if="title.msgType === 6" label-class-name="descriptions-item__label"
                            label="小程序路径">
        {{ title.wxAppUrl }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="推送时间">
        {{ title.timeDesc }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="接收人群">
        {{ title.userType === 0 ? '全部' : (title.userType === 3 ? '教职工' : '学生') }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="接收范围">
        {{ $http.getParamName(title.msgArea, msgAreaList) }}
      </el-descriptions-item>
      <el-descriptions-item v-if="title.grade" label-class-name="descriptions-item__label" label="年级">
        {{ title.grade }}
      </el-descriptions-item>
      <el-descriptions-item v-if="title.collegeId" label-class-name="descriptions-item__label"
                            label="接收院系">
        {{ title.collegeName || '全二级学院' }}
      </el-descriptions-item>
      <el-descriptions-item v-if="title.majorId" label-class-name="descriptions-item__label" label="接收专业">
        {{ title.majorName }}
      </el-descriptions-item>
      <el-descriptions-item v-if="title.deptId" label-class-name="descriptions-item__label"
                            label="接收部门">
        {{ title.deptName || '所有部门' }}
      </el-descriptions-item>
      <el-descriptions-item v-if="title.userName" label-class-name="descriptions-item__label" label="接收人">
        {{ title.userName }}
      </el-descriptions-item>
    </el-descriptions>

    <el-form ref="form" v-else :model="formData" :rules="rules" label-width="110px">
      <el-form-item label="通知标题" prop="msgTitle">
        <el-input v-model.trim="formData.msgTitle" />
      </el-form-item>
      <el-form-item label="通知内容" prop="msgProfile">
        <el-input type="textarea" v-model.trim="formData.msgProfile" />
      </el-form-item>
      <el-form-item label="链接类型" prop="msgType">
        <el-radio-group v-model="formData.msgType" size="small" @change="handleMsgType">
          <el-radio :label="1">无</el-radio>
          <el-radio :label="5">文章链接</el-radio>
          <el-radio :label="6">小程序</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="formData.msgType===5" label="链接地址" prop="linkUrl">
        <el-input v-model.trim="formData.linkUrl" />
      </el-form-item>
      <el-form-item v-if="formData.msgType===6" label="小程序原始ID" prop="wxAppId">
        <tool-tips-group is-flex tips-content="【小程序原始ID】请通过【管理小程序原始ID】按钮添加。">
          <el-select v-model="wxAppName" clearable @change="handleWxAppId">
            <el-option
              v-for="{paramValue, paramName ,id} in wxAppIdList"
              :key="id"
              :label="`${paramName}(id：${paramValue})`"
              :value="id" />
          </el-select>
        </tool-tips-group>
      </el-form-item>
      <el-form-item v-if="formData.msgType===6" label="小程序路径" prop="wxAppUrl">
        <el-input v-model.trim="formData.wxAppUrl" />
      </el-form-item>
      <el-form-item label="接收人群" prop="userType">
        <el-radio-group v-model="formData.userType" size="small" @change="handleUserType">
          <el-radio-button :label="0" :disabled="formData.msgArea===5||formData.msgArea===1||formData.msgArea===3">
            全部
          </el-radio-button>
          <el-radio-button :label="3" :disabled="formData.msgArea===3">教职工</el-radio-button>
          <el-radio-button :label="2" :disabled="formData.msgArea===5">学生</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="接收范围" prop="msgArea">
        <el-radio-group v-model="formData.msgArea" size="small" @change="handleMsgArea">
          <el-radio-button
            v-for="{paramValue,paramName} in msgAreaList"
            :key="paramValue"
            :label="Number(paramValue)"
          >{{ paramName }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="formData.msgArea === 3 || formData.msgArea === 4" label="年级">
        <el-select v-model="formData.grade" :disabled="formData.userType===3">
          <el-option
            v-for="{paramValue} in $store.state.systemParam.grade"
            :key="paramValue"
            :label="paramValue"
            :value="paramValue" />
        </el-select>
      </el-form-item>
      <el-form-item v-if="formData.msgArea===3||formData.msgArea===4" label="接收院系" prop="collegeId" key="collegeId">
        <el-select v-model="formData.collegeId" :disabled="!!$store.state.login.userInfo.collegeId"
                   @change="changeCollege" filterable clearable>
          <el-option label="全二级学院" value="-1" v-if="formData.msgArea===4" />
          <el-option
            v-for="{collegeName, id} in collegeList"
            v-show="!/公寓服务中心/g.test(collegeName)"
            :label="collegeName"
            :value="id"
            :key="id" />
        </el-select>
      </el-form-item>
      <el-form-item v-if="formData.msgArea===3" label="接收专业" prop="majorId" key="majorId">
        <el-select v-model="formData.majorId"
                   :disabled="!majorList.length"
                   @change="changeMajor" filterable clearable>
          <el-option
            v-for="{majorName, id} in majorList"
            :label="majorName"
            :value="id"
            :key="id" />
        </el-select>
      </el-form-item>
      <!--      <el-form-item label="接收班级" prop="classesId">-->
      <!--        <el-select v-model="formData.classesId"-->
      <!--                   :disabled="!classList.length"-->
      <!--                   @change="changeClass" filterable clearable>-->
      <!--          <el-option-->
      <!--            v-for="{className, id} in classList"-->
      <!--            :label="className"-->
      <!--            :value="id"-->
      <!--            :key="id" />-->
      <!--        </el-select>-->
      <!--      </el-form-item>-->
      <el-form-item v-if="formData.msgArea===1" label="接收人" prop="userId" key="userId">
        <tool-tips-group is-flex
                         tips-content="名字搜索最少输入2个汉字，身份证/录取编号搜索最少输入6位数字。<br/>只能搜索所管辖学院的学生。">
          <div class="flex">
            <el-autocomplete
              class="flex-1"
              :disabled="!formData.userType||userSelectDisabled"
              v-model="formData.value"
              :fetch-suggestions="querySearchAsync"
              placeholder="学生名、身份证号、录取编号"
              @select="handleSelect" />
            <el-button @click="clearUserSelect">清空</el-button>
          </div>
        </tool-tips-group>
      </el-form-item>
      <el-form-item v-if="this.formData.msgArea===5" label="接收部门" prop="deptId" key="deptId">
        <el-select
          v-model="formData.deptId"
          filterable
          clearable
        >
          <el-option label="所有部门" value="-1" />
          <el-option
            v-for="{deptName, id} in departmentList"
            :label="deptName"
            :value="id"
            :key="id" />
        </el-select>
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input v-model.trim="formData.remarks" />
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary"
                 v-if="!id"
                 @click="handleSave">确 定
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { sendNoticeApi } from '@/api/notice'
import dialog from '@/vue/mixins/dialog'
import CampusSchMajorClasses from '@/vue/mixins/campusSchMajorClasses'
import userAutocomplete from '@/vue/mixins/user-autocomplete'
import noticeDialogMixins from '@/views/pages/appManage/notice/mixins/notice-dialog-mixins'

export default {
  name: 'addNotice',
  mixins: [dialog, CampusSchMajorClasses, userAutocomplete, noticeDialogMixins],
  data () {
    return {
      formData: {
        msgTitle: '', // 消息标题
        msgProfile: '' // 消息内容
      },
      rules: {
        msgTitle: [{
          required: true,
          message: '请输入通知标题',
          trigger: 'blur'
        },
          {
            max: 50,
            message: '不能超过50字',
            trigger: 'blur'
          }],
        msgProfile: [{
          required: true,
          message: '请通知内容',
          trigger: 'blur'
        },
          {
            max: 300,
            message: '不能超过300字',
            trigger: 'blur'
          }]
      }
    }
  },
  methods: {
    async handleSave () {
      if (this.formData.collegeId === '-1') {
        this.formData.collegeId = ''
      }
      if (this.formData.deptId === '-1') {
        this.formData.deptId = ''
      }
      this.$refs.form.clearValidate()
      await this.save('消息通知', sendNoticeApi, '消息推送成功！')
    }
  }
}
</script>
